import React, { useState, useEffect } from 'react';
import '../styles/style.css'
import { useStyles } from '../styles/contact.styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import logoOKEBlack from '../images/icons/logoOKEBlack.svg';
import logoCivileo from '../images/logo-civileo.svg'
import MuiLink from '@material-ui/core/Link';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

const Form = () => {
    const classes = useStyles();
    const [firstInputValue, setfirstInputValue] = useState();
    const [secondInputValue, setsecondInputValue] = useState();
    const [thirdInputValue, setthirdInputValue] = useState();
    const [fourthInputValue, setfourthInputValue] = useState();
    const [fifthInputValue, setfifthInputValue] = useState();
    const [sixthInputValue, setsixthInputValue] = useState();

    const [firstRadioValue, setfirstRadioValue] = useState();
    const [secondRadioValue, setsecondRadioValue] = useState();
    const [thirdRadioValue, setthirdRadioValue] = useState();
    const [fourthRadioValue, setfourthRadioValue] = useState();
    const [fifthRadioValue, setfifthRadioValue] = useState();
    const [sixthRadioValue, setsixthRadioValue] = useState();


    useEffect(() => {
        secondRadioValue === 'monitoring-center-no' && setfifthInputValue("")
    }, [secondRadioValue]);

    useEffect(() => {
        firstRadioValue === "led-lights-no" && setthirdInputValue("");
        firstRadioValue === "led-lights-yes" && setfourthInputValue("");
    }, [firstRadioValue])

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);


    const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        setIsLoading(true);
        setIsError(false);
        axios({
            method: 'post',
            url: 'https://getform.io/f/e0eef002-195a-4a1f-88d4-b7b7dd71b62d',
            data: new FormData(form),
        })
            .then(r => {
                setIsSuccess(true);
            })
            .catch(r => {
                setIsError(true);
            })
            .finally(r => {
                setIsLoading(false);
            })
    };

    return (
        <div class="formWrapper">
            <form class="container" action="https://getform.io/f/e0eef002-195a-4a1f-88d4-b7b7dd71b62d" method="POST" id="form" onSubmit={handleOnSubmit}>

                <a href="https://civileo.pl" target="_blank"><img src={logoCivileo} alt="" class="brand-logo" /></a>
                <h1 class="header">Civileo Smart City <br /> Formularz wymagań</h1>
                <h2 class="subtitle">Prosimy o zapoznanie się z treścią i wypełnienie poniższego formularza, <br /> byśmy mogli przygotować dla
            Państwa spersonalizowaną ofertę.</h2>
                <div class="divider"> </div>
                <label for="name">
                    Miejscowość, imię i nazwisko osoby wypełniającej formularz:
        </label>
                <input type="text" id="name" name="name" onChange={e => setfirstInputValue(e.target.value)} value={firstInputValue} required />
                <span id="error-name" class="error-message"></span>

                <label for="staff">
                    Jakie służby w gminie są operatorem lub/i użytkownikiem monitoringu?
        </label>
                <textarea id="staff" name="staff" onChange={e => setsecondInputValue(e.target.value)} value={secondInputValue} required></textarea>
                <span id="error-staff" class="error-message"></span>

                <p class="form__paragraph">
                    Czy w gminie zostało wymienione oświetlenie na energooszczędne LED?
        </p>
                <div class="radio-group" onChange={e => setfirstRadioValue(e.target.value)} value={firstRadioValue}>
                    <div>
                        <input type="radio" id="led-lights-yes" name="led-lights" value="led-lights-yes" required />
                        <label for="led-lights-yes">Tak</label>
                    </div>
                    <div>
                        <input type="radio" id="led-lights-no" name="led-lights" value="led-lights-no" />
                        <label for="led-lights-no">Nie</label>
                    </div>
                </div>
                <span id="error-led-lights" class="error-message"></span>

                <label for="led-lights-yes-desc" class={firstRadioValue === 'led-lights-yes' ? "" : "dimmed"}>
                    Jeśli na powższe pytanie odpowiedziałeś TAK,
                    prosimy o opisanie w jakim stopniu zostało wymienione oświetlenie w gminie.
        </label>
                <input type="text" id="led-lights-yes-desc" name="led-lights-yes-desc" class={firstRadioValue === 'led-lights-yes' ? "" : "dimmed"} onChange={e => setthirdInputValue(e.target.value)} value={thirdInputValue} required
                    disabled={firstRadioValue === 'led-lights-yes' ? false : true} />
                <span id="error-led-lights-yes-desc" class="error-message"></span>

                <label for="led-lights-no-desc" class={firstRadioValue === 'led-lights-no' ? "" : "dimmed"}>
                    Jeśli w gminie nie wymieniono oświetlenia (odpowiedź NIE),
                    czy gmina planuje wymianę oświetlenia na energooszczędne w najbliższych latach?
        </label>
                <textarea id="led-lights-no-desc" name="led-lights-no-desc" class={firstRadioValue === 'led-lights-no' ? "" : "dimmed"} onChange={e => setfourthInputValue(e.target.value)} value={fourthInputValue} required
                    disabled={firstRadioValue === 'led-lights-no' ? false : true}></textarea>
                <span id="error-led-lights-no-desc" class="error-message"></span>

                <p class="form__paragraph" class="form__paragraph">
                    Czy gmina dysponuje centrum monitoringu?
        </p>
                <div class="radio-group" onChange={e => setsecondRadioValue(e.target.value)} value={secondRadioValue}>
                    <div>
                        <input type="radio" id="monitoring-center-yes" name="monitoring-center" value="monitoring-center-yes" required />
                        <label for="monitoring-center-yes">Tak</label>
                    </div>
                    <div>
                        <input type="radio" id="monitoring-center-no" name="monitoring-center" value="monitoring-center-no" />
                        <label for="monitoring-center-no">Nie</label>
                    </div>
                </div>
                <span id="error-monitoring-center" class="error-message"></span>

                <label for="workers-per-camera" class={secondRadioValue === 'monitoring-center-yes' ? "" : "dimmed"}>
                    Jeśli na powższe pytanie odpowiedziałeś TAK,
                    prosimy o określenie ilu pracowników przypada na jaką ilość kamer.
        </label >
                <textarea
                    id="workers-per-camera"
                    name="workers-per-camera"
                    onChange={e => setfifthInputValue(e.target.value)} value={fifthInputValue}
                    required={'monitoring-center-yes' ? true : false}
                    class={secondRadioValue === 'monitoring-center-yes' ? "" : "dimmed"}
                    disabled={secondRadioValue === 'monitoring-center-yes' ? false : true}
                ></textarea>
                <span id="error-workers-per-camera" class="error-message"></span>

                <p class="form__paragraph">
                    Czy pracownicy monitoringu wspomagani są analitykami obrazu?
        </p>
                <div class="radio-group" onChange={e => setthirdRadioValue(e.target.value)} value={thirdRadioValue}>
                    <div>
                        <input type="radio" id="image-analytics-support-yes" name="image-analytics-support"
                            value="image-analytics-support-yes" required />
                        <label for="image-analytics-support-yes">Tak</label>
                    </div>
                    <div>
                        <input type="radio" id="image-analytics-support-no" name="image-analytics-support"
                            value="image-analytics-support-no" />
                        <label for="image-analytics-support-no">Nie</label>
                    </div>
                </div>
                <span id="error-image-analytics-support" class="error-message"></span>

                <label for="ai-lamp-sensors">
                    Jakie sensory inteligentnej lampy są potrzebne gminie?
        </label>
                <textarea id="ai-lamp-sensors" name="ai-lamp-sensors" onChange={e => setsixthInputValue(e.target.value)} value={sixthInputValue} required></textarea>
                <span id="error-ai-lamp-sensors" class="error-message"></span>

                <p class="form__paragraph">
                    Czy gmina posiada inwentaryzację oświetlenia?
        </p>
                <div class="radio-group" onChange={e => setfourthRadioValue(e.target.value)} value={fourthRadioValue}>
                    <div>
                        <input type="radio" id="lights-inventory-yes" name="lights-inventory" value="lights-inventory-yes" required />
                        <label for="lights-inventory-yes">Tak</label>
                    </div>
                    <div>
                        <input type="radio" id="lights-inventory-no" name="lights-inventory" value="lights-inventory-no" />
                        <label for="lights-inventory-no">Nie</label>
                    </div>
                </div>
                <span id="error-lights-inventory" class="error-message"></span>

                <p class="form__paragraph">
                    Czy obecnie używany system monitoringu wizyjnego wykorzystuje dedykowane pomieszczenie dla serwerów?
        </p>
                <div class="radio-group" onChange={e => setfifthRadioValue(e.target.value)} value={fifthRadioValue}>
                    <div>
                        <input type="radio" id="server-room-yes" name="server-room" value="server-room-yes" required />
                        <label for="server-room-yes">Tak</label>
                    </div>
                    <div>
                        <input type="radio" id="server-room-no" name="server-room" value="server-room-no" />
                        <label for="server-room-no">Nie</label>
                    </div>
                </div>
                <span id="error-server-room" class="error-message"></span>

                <p class="form__paragraph">
                    Czy gmina posiada system, który chciałaby zintegrować z Civileo?
        </p>
                <div class="radio-group" onChange={e => setsixthRadioValue(e.target.value)} value={sixthRadioValue}>
                    <div>
                        <input type="radio" id="existing-system-integration-yes" name="existing-system-integration"
                            value="existing-system-integration-yes" required />
                        <label for="existing-system-integration-yes">Tak</label>
                    </div>
                    <div>
                        <input type="radio" id="existing-system-integration-no" name="existing-system-integration"
                            value="existing-system-integration-no" />
                        <label for="existing-system-integration-no">Nie</label>
                    </div>
                </div>
                <span id="error-existing-system-integration" class="error-message"></span>
                <button type="submit" id="submit-btn" class="submit-btn" disabled={isLoading || isSuccess}>{isLoading ? <CircularProgress style={{ color: "white" }} size={20} /> : "Wyślij"}</button>

                <div id="thank-you-message" class={isSuccess ? "" : "hidden"}>
                    <div class="divider"> </div>


                    <p class="thank-you-hd">
                        Dziękujemy za poświęcony czas.
            </p>
                    <p class="thank-you-desc">
                        Jeśli są Państwo zainteresowani naszą ofertą,&nbsp;bądź chcielibyście uzyskać szczegółowe
                        informacje na&nbsp;temat oferowanych przez nas produktów i&nbsp;usług, zapraszamy&nbsp;do&nbsp;kontaktu.
                        Jesteśmy&nbsp;do&nbsp;Państwa&nbsp;dyspozycji.
            </p>
                </div>
                <div id="thank-you-message" class={isError ? "" : "hidden"}>
                    <div class="divider"> </div>


                    <p class="thank-you-hd">
                        Coś poszło nie tak. Spróbój ponownie lub skontaktuj się z administratorem.
            </p>

                </div>
            </form >
            <Box className={classes.footerContainerForForm}>
                <Grid
                    container
                    className={classes.footer}
                    justify="space-around"
                    alignItems="center"
                >
                    <Grid
                        item
                        container
                        justify="space-between"
                        xs={9}
                    >
                        <Grid
                            item
                            xs
                            className={
                                classes.footerItemWrapper
                            }
                        >
                            <Box>
                                OKE Poland Sp. z o.o.
                                                <br />
                                {`ul. Heweliusza 11, 80${ '\u2011' }890${ '\u00a0' }Gdańsk`}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs
                            className={
                                classes.footerItemWrapper
                            }
                        >
                            <Box>
                                tel. +48 58 321 72 46
                                                <br />
                                                www.oke.pl
                                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs
                            className={
                                classes.footerItemWrapper
                            }
                        >
                            <Box>
                                <a class="maillink" href="mailto:diana.bogusz@civileo.pl">diana.bogusz@civileo.pl</a>
                                <br />
                                <a class="maillink" href="mailto:sandra.baruffi@civileo.pl">sandra.baruffi@civileo.pl</a>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} className={classes.link}>
                        <MuiLink
                            href="https://www.oke.pl"
                            target="_blank"
                        >
                            <img
                                className={classes.okeLogo}
                                src={logoOKEBlack}
                                alt="Logo OKE"
                            />
                        </MuiLink>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default Form